export default {
    items: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'icon-speedometer'
        },
        {
            name: 'Debiteuren',
            url : '/debtors',
            icon : 'icon-user'
        },
        {
            name: 'Instellingen',
            url: '/settings',
            icon: 'icon-settings'
        }
    ]
}
